@font-face {
  font-family: zephtext;
  src: url("zephtext.9c4a7340.ttf");
  unicode-range: U+370-3FF, U+1F??;
  font-size: 125%;
}

* {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

body {
  color: #000;
  text-align: left;
  font-family: zephtext, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.66em;
}

p {
  margin-top: 0;
  line-height: 1.5em;
}

h1, h2 {
  text-align: center;
  grid-column: span 14;
  margin: 0;
  font-weight: 300;
  line-height: 1.2em;
}

h1 + h2 {
  margin: 10px 0;
}

h1 {
  font-size: 2.5rem;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child {
  margin-top: 0;
}

h2 {
  font-size: 1.5em;
}

h3, h4 {
  margin: .8em 0 .7em;
  font-weight: 400;
}

a {
  color: #b22222;
  text-decoration: none;
}

header nav a {
  color: #696969;
  margin-right: 36px;
  text-decoration: none;
}

footer {
  text-align: center;
  color: #696969;
  font-size: .75rem;
}

.small-caps {
  font-variant: small-caps;
}

.centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

.tight {
  margin: 0;
  padding: 0;
}

code {
  font-size: 12px;
}

.xml, .diff {
  font-family: monospace;
  line-height: 12px;
}

.bold {
  font-weight: 600;
}

.semi-bold {
  font-weight: 400;
}

.firebrick, .red {
  color: #b22222;
}

.orangered, .orange {
  color: #ff4500;
}

.darkgreen, .green {
  color: #006400;
}

.token {
  font-size: 1.1em;
  line-height: 1.4em;
}

[contenteditable] {
  cursor: pointer;
}

.sym {
  font-family: Arial unicode ms;
}

.smaller {
  font-size: 85% !important;
}

/*# sourceMappingURL=index.25ce5d30.css.map */
